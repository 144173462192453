import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
//import Banner from './Screens/Banner';
import LoginCallback from './Components/LoginCallback';
import Loja from './Screens/Loja';
import Conclusao from './Screens/Conclusao';
import PoliticasDeUso from './Screens/PoliticasDeUso';
import LogoutCallback from './Components/LogoutCallback';
// import AssinantesAceiteLgpdSorteTotal from './Screens/AssinantesAceiteLgpdSorteTotal';
// import UserSession from './Services/UserSession';
// import NSCAuthService from './Services/NSCAuthService';
// import UrlHelper from './Helpers/UrlHelper';
// import ApiService from './Services/ApiService';
// import Offers from './Screens/Offers';
// import ConclusaoTopMed from './Screens/ConclusaoTopMed';
import Layout from './Layout';
import ConclusaoDigital from './Screens/ConclusaoDigital';
import ConclusaoDigitalClube from './Screens/ConclusaoDigitalClube';
import ConclusaoDigitalUnisul from './Screens/ConclusaoDigitalUnisul';
import ConclusaoDigitalError from './Screens/ConclusaoDigitalError';
import ConclusaoGlobo from './Screens/ConclusaoGlobo';
import ConclusaoComboLight from './Screens/ConclusaoComboLight';
import Foot from './Components/Foot';

class AppRouter extends Component {

  ignore = {
    '/login-callback': true,
  }

  logout = {
    '/logout-callback': true
  }

  requireAuthentication = {
    '/loja': true,
    '/assinante-aceite-termos': true
  }

  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.onRouteChanged = this.onRouteChanged.bind(this);
    this.login = this.login.bind(this);
    this.state = {
      userData: {}
    };
  }

  async componentDidMount() {
    return await this.login();
  }

  async onRouteChanged() {
    return await this.login();
  }

  async login() {
    if (this.ignore[window.location.pathname]) {
      return;
    }

    if (this.logout[window.location.pathname]) {
      await this.setState({
        userData: {}
      });
      return;
    }

    //console.log(2);
    //await this.setState({
    //  userData: await UserSession.getUserData()
    //});

    // if (!this.state.userData.isLoggedIn) {
    //   const resp = await NSCAuthService.createCode();
    //   if (resp.status === 'AUTHENTICATED') {
    //     await ApiService.login(resp.code);
    //     console.log(2);
    //     await this.setState({
    //       userData: await UserSession.getUserData()
    //     });
    //   } else if (this.requireAuthentication[window.location.pathname]) {
    //     window.location.href = UrlHelper.buildLoginUrl(window.location.pathname);
    //   }
    // }
  }

  render() {
    return (
      <div>
        <Router onRouteChanged={this.onRouteChanged}>
          <Switch>
            <Route path="/login-callback" component={Layout(LoginCallback)} />
            <Route path="/logout-callback" component={Layout(LogoutCallback)} />
            <Route path="/loja-digital" component={Layout(() => <Loja product={'digital'} />, 1)} />
            <Route path="/loja-digital-anual" component={Layout(() => <Loja product={'digitalanual'} />, 1)} />
            <Route path="/loja-combo-light" component={Layout(() => <Loja product={'combolight'} />, 1)} />
            <Route path="/loja-digital-nsc" component={Layout(() => <Loja product={'digital2'} />, 1)} />
            <Route path="/loja-digital-nsc-total" component={Layout(() => <Loja product={'digital3'} />, 1)} />
            <Route path="/loja-digital-total" component={Layout(() => <Loja product={'digital4'} />, 1)} />
            <Route path="/loja-nsc-globo-mais" component={Layout(() => <Loja product={'globo'} />, 1)} />
            <Route path="/nsc-globo-anual" component={Layout(() => <Loja product={'globoanual'} />, 1)} />
            <Route path="/loja-digital-clube" component={Layout(() => <Loja product={'clube'} />, 1)} />
            <Route path="/loja-nsc-total" component={Layout(() => <Loja product={'clube2'} />, 1)} />
            <Route path="/loja-clube-anual" component={Layout(() => <Loja product={'clube3'} />, 1)} />
            <Route path="/clube-nsc-anual" component={Layout(() => <Loja product={'clube4'} />, 1)} />
            <Route path="/clube-nsc-mime" component={Layout(() => <Loja product={'clubemime'} />, 1)} />
            <Route path="/clube-35-anual" component={Layout(() => <Loja product={'clube35anual'} />, 1)} />
            <Route path="/clube-35-anual-cs" component={Layout(() => <Loja product={'clube35anualcs'} />, 1)} />
            <Route path="/clube-35-anual-ads" component={Layout(() => <Loja product={'clube35anualads'} />, 1)} />
            <Route path="/summit-cidades" component={Layout(() => <Loja product={'clube5'} />, 1)} />
            <Route path="/loja-clube-desconto" component={Layout(() => <Loja product={'clube2x'} />, 1)} />
            <Route path="/loja-clube-desconto-cs" component={Layout(() => <Loja product={'clube2xcs'} />, 1)} />
            <Route path="/assine-clube-nsc-anual-brinde" component={Layout(() => <Loja product={'clubebrinde'} />, 1)} />
            <Route path="/assine-clube-nsc-25-anos-loja" component={Layout(() => <Loja product={'clube25anosloja'} />, 1)} />
            <Route path="/assine-clube-nsc-25-anos-cs" component={Layout(() => <Loja product={'clube25anoscs'} />, 1)} />
            <Route path="/assine-clube-nsc-25-anos-ads" component={Layout(() => <Loja product={'clube25anosads'} />, 1)} />
            <Route path="/nsc-total-light-anual" component={Layout(() => <Loja product={'digital5'} />, 1)} />
            <Route path="/clube-nsc-anual-cs" component={Layout(() => <Loja product={'clube4cs'} />, 1)} />
            <Route path="/loja-nsc-total-cs" component={Layout(() => <Loja product={'clube2cs'} />, 1)} />
            <Route path="/nsc-total-light-anual-cs" component={Layout(() => <Loja product={'digital5cs'} />, 1)} />
            <Route path="/loja-digital-nsc-cs" component={Layout(() => <Loja product={'digital2cs'} />, 1)} />
            <Route path="/loja-clube-degustacao" component={Layout(() => <Loja product={'clubepromo'} />, 1)} />
            <Route path="/loja-clube-anual-brinde" component={Layout(() => <Loja product={'clube6'} />, 1)} />
            <Route path="/loja-nsc-total-seis-meses" component={Layout(() => <Loja product={'clube6meses'} />, 1)} />
            <Route path="/loja-nsc-total-seis-meses-cs" component={Layout(() => <Loja product={'clube6mesescs'} />, 1)} />
            <Route path="/loja-nsc-total-seis-meses-loja" component={Layout(() => <Loja product={'clube6mesesloja'} />, 1)} />
            <Route path="/clube-nsc-week-ads" component={Layout(() => <Loja product={'clubeweekads'} />, 1)} />
            <Route path="/clube-nsc-week-cs" component={Layout(() => <Loja product={'clubeweekcs'} />, 1)} />
            <Route path="/clube-nsc-week-loja" component={Layout(() => <Loja product={'clubeweekloja'} />, 1)} />
            <Route path="/clube-nsc-anual-oferta-especial" component={Layout(() => <Loja product={'clube7'} />, 1)} />
            <Route path="/clube-nsc-anual-mes-do-cliente-cs" component={Layout(() => <Loja product={'clubeclientecs'} />, 1)} />
            <Route path="/clube-nsc-anual-mes-do-cliente-ads" component={Layout(() => <Loja product={'clubeclienteads'} />, 1)} />
            <Route path="/clube-nsc-anual-mes-do-cliente-loja" component={Layout(() => <Loja product={'clubeclienteloja'} />, 1)} />
            <Route path="/clube-nsc-anual-padrao" component={Layout(() => <Loja product={'clube8'} />, 1)} />
            <Route path="/nsc-total-light-anual-padrao" component={Layout(() => <Loja product={'digital6'} />, 1)} />
            <Route path="/clube-nsc-anual-7-90" component={Layout(() => <Loja product={'clube9'} />, 1)} />
            <Route path="/clube-nsc-anual-17-90" component={Layout(() => <Loja product={'clube10'} />, 1)} />
            <Route path="/clube-nsc-anual-oab" component={Layout(() => <Loja product={'clubeoab'} />, 1)} />
            <Route path="/clube-nsc-anual-simesc" component={Layout(() => <Loja product={'clubesimesc'} />, 1)} />
            <Route path="/clube-nsc-universidades" component={Layout(() => <Loja product={'clubeuni'} />, 1)} />
            <Route path="/loja-nsc-total-padrao" component={Layout(() => <Loja product={'clube11'} />, 1)} />
            <Route path="/loja-nsc-total-diadoconsumidor" component={Layout(() => <Loja product={'clube12'} />, 1)} />
            <Route path="/loja-digital-padrao-cs" component={Layout(() => <Loja product={'digital7cs'} />, 1)} />
            <Route path="/loja-digital-padrao-loja" component={Layout(() => <Loja product={'digital7loja'} />, 1)} />
            <Route path="/loja-digital-padrao-ads" component={Layout(() => <Loja product={'digital7ds'} />, 1)} />
            <Route path="/loja-digital-padrao" component={Layout(() => <Loja product={'digital7'} />, 1)} />
            <Route path="/loja-clube-silva" component={Layout(() => <Loja product={'club1'} />, 1)} />
            <Route path="/loja-clube-seabra" component={Layout(() => <Loja product={'club2'} />, 1)} />
            <Route path="/loja-clube-spautz" component={Layout(() => <Loja product={'club3'} />, 1)} />
            <Route path="/loja-clube-benetti" component={Layout(() => <Loja product={'club4'} />, 1)} />
            <Route path="/loja-clube-faraco" component={Layout(() => <Loja product={'club5'} />, 1)} />
            <Route path="/loja-clube-nasser" component={Layout(() => <Loja product={'club6'} />, 1)} />
            <Route path="/loja-clube-coelho" component={Layout(() => <Loja product={'club7'} />, 1)} />
            <Route path="/loja-clube-uggioni" component={Layout(() => <Loja product={'club8'} />, 1)} />
            <Route path="/loja-clube-machado" component={Layout(() => <Loja product={'club9'} />, 1)} />
            <Route path="/loja-clube-igor" component={Layout(() => <Loja product={'club10'} />, 1)} />
            <Route path="/loja-clube-saavedra" component={Layout(() => <Loja product={'club11'} />, 1)} />
            <Route path="/loja-clube-ze" component={Layout(() => <Loja product={'club12'} />, 1)} />
            <Route path="/loja-digital-silva" component={Layout(() => <Loja product={'digitalc1'} />, 1)} />
            <Route path="/loja-digital-seabra" component={Layout(() => <Loja product={'digitalc2'} />, 1)} />
            <Route path="/loja-digital-spautz" component={Layout(() => <Loja product={'digitalc3'} />, 1)} />
            <Route path="/loja-digital-benetti" component={Layout(() => <Loja product={'digitalc4'} />, 1)} />
            <Route path="/loja-digital-faraco" component={Layout(() => <Loja product={'digitalc5'} />, 1)} />
            <Route path="/loja-digital-nasser" component={Layout(() => <Loja product={'digitalc6'} />, 1)} />
            <Route path="/loja-digital-coelho" component={Layout(() => <Loja product={'digitalc7'} />, 1)} />
            <Route path="/loja-digital-uggioni" component={Layout(() => <Loja product={'digitalc8'} />, 1)} />
            <Route path="/loja-digital-machado" component={Layout(() => <Loja product={'digitalc9'} />, 1)} />
            <Route path="/loja-digital-igor" component={Layout(() => <Loja product={'digitalc10'} />, 1)} />
            <Route path="/loja-digital-saavedra" component={Layout(() => <Loja product={'digitalc11'} />, 1)} />
            <Route path="/loja-digital-ze" component={Layout(() => <Loja product={'digitalc12'} />, 1)} />

            <Route path="/politicas-de-uso" component={Layout(PoliticasDeUso)} />
            <Route path="/assinatura" render={() => <Redirect to="/ofertas" />} />  
            <Route path="/conclusao" exact component={Layout(Conclusao)} />
            <Route path="/conclusao/:error_code" exact component={Layout(Conclusao)} />
            <Route path="/conclusao-digital" exact component={Layout(ConclusaoDigital, 3, true)} />
            <Route path="/conclusao-digital-clube" exact component={Layout(ConclusaoDigitalClube, 3, true)} />
            <Route path="/conclusao-digital-unisul" exact component={Layout(ConclusaoDigitalUnisul)} />
            <Route path="/conclusao-combo-light" exact component={Layout(ConclusaoComboLight, 3, true)} />
            <Route path="/conclusao-digital/:error_code?" exact component={Layout(ConclusaoDigitalError)} />
            <Route path="/conclusao-nsc-globo-mais" exact component={Layout(ConclusaoGlobo, 3, true)} />
            <Route exact path="/" render={() => <Redirect to="/ofertas" />} />
            <Route path="/digital" render={() => {(window.location = ("https://loja.nsctotal.com.br/assine"))} }/>
            <Route path="/ofertas" render={() => {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const gl = urlParams.get('_gl');
                const redirectUrl = `https://loja.nsctotal.com.br/assine${gl ? `?gl=${gl}` : ''}`;
                window.location.href = redirectUrl;
              }} />
            <Route path="/clube-nsc" render={() => {(window.location = "https://loja.nsctotal.com.br/clube")} }/>
          </Switch>
        </Router>
        <Foot />
      </div>
    );
  }
}

export default AppRouter;
